$(document).ready(function() {
    changeCapthaSize();

  $(".fancybox").fancybox({
      autoResize: true,
      padding: 12,
      openEffect: 'elastic',
      closeEffect: 'elastic',
      openSpeed: 'slow',
      closeSpeed: 'slow'
  });

  $(".home-slider").each(function () {
    $(this).slick({
      fade: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: ($(this).data('pause') || 4) * 1000,
      speed: ($(this).data('speed') || 1) * 1000,
      pauseOnFocus: false,
      pauseOnHover: false,
        responsive: [{
            breakpoint: 960,
            settings: {
                arrows: false
            }
        }]
    });
  });

  $(".social__link--search").mouseenter(function () {
      $(this).find("img").attr("src", "themes/individual/public/images/temp/search-violet.png");
  });

    $(".social__link--search").mouseleave(function () {
        $(this).find("img").attr("src", "themes/individual/public/images/temp/search-img.png");
    });

  $(".home-staff__slider").slick({
      slidesToShow: 4,
      responsive: [
          {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3
              }
          },
          {
              breakpoint: 980,
              settings: {
                  slidesToShow: 2
              }
          },
          {
              breakpoint: 680,
              settings: {
                  slidesToShow: 1
              }
          }
      ]
  });


  $(".toggle-menu").click(function(){
    $(this).toggleClass("on");
    $(".hidden-menu").slideToggle(200);
    return false;
  });

    $(window).scroll(function(){
        if($(this).scrollTop() > $(this).height()){
            $(".top").addClass("active");
        } else {
            $(".top").removeClass("active");
        }
    });

    $("body").on("click", ".top", function(){
        $("html, body").animate({scrollTop: 0}, "slow");
    });
});


// HEADER FIX;
(() => {
  $.fn.extend({
    checkOffsetTop: (callback) => {
      const check = (offset) => {
        if (offset > 0) {
          return callback(true);
        } else {
          return callback(false);
        }
      }

      check($(window).scrollTop());
      $(window).on('scroll', () => {
        check($(window).scrollTop());
      });
    }
  });

  $('.header').checkOffsetTop((status) => {
    if(status === true) {
      $('.header').addClass('fix');
    } else {
      $('.header').removeClass('fix');
    }
  });
})();


// STAFF CERTIFICATES TOGGLE
(() => {
  const button = $('.staff-sertificate__toggle');
  const list = $('.staff-sertificate__list');
  const openClass = 'is-open';
  const openText = 'Свернуть';
  const closedClass = 'is-closed';
  const closedText = 'Показать все сертификаты';

  button.click(function() {
    if(button.hasClass(closedClass)) {
      button.removeClass(closedClass).addClass(openClass);
      button.text(openText);
      list.css('height', 'auto');
    } else if (button.hasClass(openClass)) {
      button.removeClass(openClass).addClass(closedClass);
      button.text(closedText);
      list.css('height', '346px');
    }
  });
})();

// quick search
(() => {
  $('.js-quick-search').each(function () {
    $(this).jautocomplete({
      minChars: 3,
      serviceUrl: $(this).data('quickUrl'),
      triggerSelectOnValidInput: false,
      onSelect(suggestion) {
        document.location.href = suggestion.link;
      }
    });
  });
})();

// arctic.js
(() => {
  $(document).on('click', '.js-ajax-link', function (event) {
    event.preventDefault();
    const modalUrl = $(this).data('pk') || $(this).data('action');
    $.arcticmodal('close');
    $.arcticmodal({
      type: 'ajax',
      url: modalUrl,
        afterOpen: function () {
            changeCapthaSize();
        }
    });
    return false;
  });

  $(document).on('submit', '.js-box-modal form, form.js-ajax-form', function (event) {
    event.preventDefault();
    const self = $(this);

    self.ajaxSubmit(function (data) {
      if (data.indexOf('js:') === 0) {
        $.globalEval(data.substring(3));
      } else {
        $.arcticmodal('close');
        $.arcticmodal({
          content: data
        });
      }
    });
    return false;
  });
})();


function changeCapthaSize() {
    var reCaptchaWidth = 302;
    var containerWidth = $('form').width();
    if(reCaptchaWidth > containerWidth) {
        var reCaptchaScale = containerWidth / reCaptchaWidth;
        $('.g-recaptcha').css({
            'transform':'scale('+reCaptchaScale+')',
            'transform-origin':'left top'
        });
      }
};

$(window).resize(function(){
    changeCapthaSize();
});
